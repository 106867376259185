#portfolio {
  background: #ffffff;
  padding-top: 12rem;
  padding-bottom: 12rem;
}

#portfolio .projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

#portfolio .card {
  width: calc(33% - 2rem);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
  padding-bottom: 2rem;
}

#portfolio .card:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
}

#portfolio .card .card-img {
  height: 200px;
  width: 100%;
}

#portfolio .card .card-content {
  padding: 1rem 2rem;
}

#portfolio .card .card-text {
  font-size: 16px;
  text-align: justify;
}

#portfolio .card .card-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#portfolio .card .card-links .github-btn {
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #743cc3;
  color: #743cc3;
}

#portfolio .card .card-links .github-btn:hover {
  background-color: #743cc3;
  color: #ffffff;
}

#portfolio .card .card-links .demo-btn {
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #743cc3;
  color: #ffffff;
}

#portfolio .card .card-links .demo-btn:hover {
  background-color: #5d309c;
}

#portfolio .all-projects-btn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

#portfolio .all-projects-btn a {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #151515;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #151515;
}

#portfolio .all-projects-btn a:hover {
  background-color: #151515;
  color: #ffffff;
}

@media only screen and (max-width: 992px) {
  #portfolio .card {
    width: calc(50% - 2rem);
  }
}
@media only screen and (max-width: 768px) {
  #portfolio .card .card-links {
    font-size: 15px;
  }
}

@media only screen and (max-width: 576px) {
  #portfolio .card {
    width: 100%;
  }
  #portfolio .card:hover {
    box-shadow: none;
    transform: none;
  }
  #portfolio .card .card-img {
    height: 220px;
  }
  #portfolio .card .card-links {
    font-size: 15px;
  }
  #portfolio .all-projects-btn a {
    font-size: 15px;
  }
  #portfolio .all-projects-btn i {
    font-size: 124px;
  }
  #portfolio .card .card-title {
    font-size: 19px;
  }
  #portfolio .card .card-text {
    font-size: 15px;
  }
}
