#intro {
  background: #151515 url(../../assets/images/intro-bg.jpg) no-repeat center
    bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  min-height: 720px;
  display: table;
  position: relative;
  text-align: center;
}
.intro-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111111;
  opacity: 0.85;
}

.intro-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  -webkit-transform: translateY(-2.1rem);
  -ms-transform: translateY(-2.1rem);
  transform: translateY(-2.1rem);
}
.intro-content h1 {
  color: #ffffff;
  font-family: "poppins-medium", sans-serif;
  font-size: 8.4rem;
  line-height: 1.071;
  max-width: 900px;
  margin-top: 0;
  margin-bottom: 0.6rem;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.intro-content h5 {
  color: #743cc3;
  font-family: "poppins-bold", sans-serif;
  font-size: 2.3rem;
  line-height: 1.565;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.intro-content .intro-position {
  font-family: "lora-regular", serif;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: #ffffff;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.intro-content .intro-position span {
  display: inline-block;
}
.intro-content .intro-position span::after {
  content: "|";
  text-align: center;
  display: inline-block;
  padding: 0 8px 0 14px;
  color: rgba(255, 255, 255, 0.3);
}
.intro-content .intro-position span:first-child::before {
  content: "|";
  text-align: center;
  display: inline-block;
  padding: 0 14px 0 8px;
  color: rgba(255, 255, 255, 0.3);
}
.intro-content .button {
  color: #ffffff !important;
  border-color: rgba(255, 255, 255, 0.3);
  height: 6rem !important;
  line-height: 5.4rem !important;
  padding: 0 3.5rem 0 3rem !important;
  margin-top: 0.6rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
}
.intro-content .button:hover,
.intro-content .button:focus {
  border-color: #743cc3;
}

.intro-social {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 10rem;
  font-size: 4rem;
  margin: 0;
  padding: 0;
}
.intro-social li {
  display: inline-block;
  margin: 0 20px;
  padding: 0;
}
.intro-social li a {
  color: #ffffff;
}
.intro-social li a:hover {
  color: #743cc3;
}

@media only screen and (max-width: 1024px) {
  .intro-content h1 {
    font-size: 7.6rem;
  }

  .intro-social {
    font-size: 3.5rem;
  }
  .intro-social li {
    margin: 0 15px;
  }
}
@media only screen and (max-width: 768px) {
  #intro {
    min-height: 660px;
  }
  .intro-content .button {
    font-size: 1.2rem;
  }
  .intro-content h1 {
    font-size: 5.2rem;
  }
  .intro-content h5 {
    font-size: 1.8rem;
  }
  .intro-content .intro-position {
    font-size: 1.3rem;
  }
  .intro-social {
    font-size: 3.2rem;
  }
  .intro-social li {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 600px) {
  #intro {
    min-height: 600px;
  }
  .intro-content .button {
    font-size: 1rem;
    padding: 0 2.5rem 0 2rem !important;
  }
  .intro-content h1 {
    font-size: 3.7rem;
    margin-bottom: 0.6rem;
  }
  .intro-content h5 {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
    letter-spacing: 0.2rem;
  }
  .intro-content .intro-position {
    font-size: 1.2rem;
  }
  .intro-content .intro-position span {
    padding: 0 0.6rem;
  }
  .intro-content .intro-position span::before,
  .intro-content .intro-position span::after {
    display: none !important;
  }
  .intro-social {
    font-size: 3rem;
  }
}
