.header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  background-color: rgba(13, 13, 13, 0.9);
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.header .nav-menu {
  list-style: none;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.header .nav-menu a {
  cursor: pointer;
  color: #ffffff;
  transition: none;
}

.header .nav-item .scrollActive {
  padding-bottom: 12px;
  border-bottom: 3px solid #743cc3;
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid #743cc3;
}

.header .navbar img {
  width: 250px;
  height: auto;
}

.header .logo {
  background-color: #743cc3;
  color: white;
  padding: 2rem 2rem;
  font-size: 5rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.header .nav-item {
  padding: 1rem;
  margin: 0 1rem;
  font-weight: 500;
}

.hamburger {
  display: none;
  font-size: 27px;
}

@media screen and (max-width: 940px) {
  .header {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
  }

  .header .navbar {
    width: 90%;
  }

  .hamburger {
    display: block;
    margin-top: 10px;
  }

  .nav-menu {
    position: fixed;
    left: -200%;
    top: 90px;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100vh;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
    margin-left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .header .logo {
    font-size: 3.5rem;
    padding: 1.7rem 1.7rem;
  }
}
