/**
 * =================================================================== 
 *
 *  Kards v1.0 Main Stylesheet
 *  url: styleshout.com
 *  03-01-2016
 *  ------------------------------------------------------------------
 *  TOC:
 *  01. webfonts and iconfonts
 *  02. base style overrides
 *  03. typography & general theme styles
 *  04. preloader
 *  05. forms
 *  06. buttons 
 *  07. other components
 *  08. common styles
 *  09. header styles
 *  10. intro
 *  11. about
 *  12. resume
 *  13. portfolio
 *  14. call-to-action section
 *  15. services
 *  16. stats
 *  17. contact
 *  18. footer
 *
 * =================================================================== 
 */

/** 
 * ===================================================================
 * 01. webfonts and iconfonts - (_document-setup.scss)
 *
 * ------------------------------------------------------------------- 
 */

@import url("../src/assets/css/fonts.css");

/** 
  * ===================================================================
  * 02. base style overrides - (_document-setup.scss)
  *
  * ------------------------------------------------------------------- 
  */
html {
  font-size: 10px;
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 9.411764705882353px;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 10px;
  }
}
@media only screen and (max-width: 400px) {
  html {
    font-size: 9.411764705882353px;
  }
}

html,
body {
  height: 100%;
}
body {
  background: #0d0d0d;
  font-family: "lora-regular", serif;
  font-size: 1.7rem;
  line-height: 3rem;
  color: #6e6e6e;
}

/**
  * links - (_document-setup.scss)
  * -------------------------------------------------------------------
  */
a,
a:visited {
  color: #000000;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a:hover,
a:focus,
a:active {
  color: #9063cf;
  outline: 0;
}

/** 
  * ===================================================================
  * 03. typography & general theme styles - (_document-setup.scss)
  *
  * ------------------------------------------------------------------- 
  */
h1,
h2,
h3,
h4,
h5,
h6,
.h01,
.h02,
.h03,
.h04,
.h05,
.h06 {
  font-family: "poppins-semibold", sans-serif;
  color: #313131;
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-bottom: 2.1rem;
}
h3,
.h03,
h4,
.h04 {
  margin-bottom: 1.8rem;
}
h5,
.h05,
h6,
.h06 {
  font-family: "poppins-bold";
  margin-bottom: 1.2rem;
}
h1,
.h01 {
  font-size: 3.1rem;
  line-height: 1.355;
  letter-spacing: -0.1rem;
}
@media only screen and (max-width: 600px) {
  h1,
  .h01 {
    font-size: 2.6rem;
    letter-spacing: -0.07rem;
  }
}
h2,
.h02 {
  font-size: 2.4rem;
  line-height: 1.25;
}
h3,
.h03 {
  font-size: 2rem;
  line-height: 1.5;
}
h4,
.h04 {
  font-size: 1.7rem;
  line-height: 1.765;
}
h5,
.h05 {
  font-size: 1.4rem;
  line-height: 1.714;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
h6,
.h06 {
  font-size: 1.3rem;
  line-height: 1.846;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
p img {
  margin: 0;
}
p.lead {
  font-family: "lora-regular", serif;
  font-size: 2rem;
  line-height: 1.8;
  color: #888888;
}
@media only screen and (max-width: 768px) {
  p.lead {
    font-size: 1.7rem;
  }
}
em,
i,
strong,
b {
  font-size: 1.7rem;
  line-height: 3rem;
  font-style: normal;
  font-weight: normal;
}
em,
i {
  font-family: "lora-italic", serif;
}
strong,
b {
  font-family: "lora-bold", serif;
}
small {
  font-size: 1.2rem;
  line-height: inherit;
}
blockquote {
  margin: 3rem 0;
  padding-left: 4rem;
  position: relative;
}
blockquote:before {
  content: "\201C";
  font-size: 8rem;
  line-height: 0px;
  margin: 0;
  color: #313131;
  font-family: arial, sans-serif;
  position: absolute;
  top: 3rem;
  left: 0;
}
blockquote p {
  font-family: georgia, serif;
  font-style: italic;
  padding: 0;
  font-size: 1.9rem;
  line-height: 1.737;
}
blockquote cite {
  display: block;
  font-size: 1.3rem;
  font-style: normal;
  line-height: 1.616;
}
blockquote cite:before {
  content: "\2014 \0020";
}
blockquote cite a,
blockquote cite a:visited {
  color: #888888;
  border: none;
}
abbr {
  font-family: "poppins-bold", serif;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.05rem;
  color: #888888;
}
var,
kbd,
samp,
code,
pre {
  font-family: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}
pre {
  padding: 2.4rem 3rem 3rem;
  background: #f1f1f1;
}
code {
  font-size: 1.4rem;
  margin: 0 0.2rem;
  padding: 0.3rem 0.6rem;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}
pre > code {
  display: block;
  white-space: pre;
  line-height: 2;
  padding: 0;
  margin: 0;
}
pre.prettyprint > code {
  border: none;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
mark {
  background: #fff49b;
  color: #000;
}
hr {
  border: solid #d2d2d2;
  border-width: 1px 0 0;
  clear: both;
  margin: 2.4rem 0 1.5rem;
  height: 0;
}

/**
  * Lists - (_document-setup.scss)  
  * -------------------------------------------------------------------
  */
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
li {
  display: list-item;
}
ol,
ul {
  margin-left: 1.7rem;
}
ul li {
  padding-left: 0.4rem;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0.6rem 0 0.6rem 1.7rem;
}
ul.disc li {
  display: list-item;
  list-style: none;
  padding: 0 0 0 0.8rem;
  position: relative;
}
ul.disc li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #9063cf;
  position: absolute;
  left: -17px;
  top: 11px;
  vertical-align: middle;
}
dt {
  margin: 0;
  color: #9063cf;
}
dd {
  margin: 0 0 0 2rem;
}

/**
  * tables - (_document-setup.scss)  
  * -------------------------------------------------------------------
  */
table {
  border-width: 0;
  width: 100%;
  max-width: 100%;
  font-family: "lora-regular", sans-serif;
}
th,
td {
  padding: 1.5rem 3rem;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}
th {
  color: #313131;
  font-family: "poppins-bold", sans-serif;
}
td {
  line-height: 1.5;
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/**
  * Spacing - (_document-setup.scss)  
  * -------------------------------------------------------------------
  */
button,
.button {
  margin-bottom: 1.2;
}
fieldset {
  margin-bottom: 1.5rem;
}
input,
textarea,
select,
pre,
blockquote,
figure,
table,
p,
ul,
ol,
dl,
form,
.fluid-video-wrapper,
.ss-custom-select {
  margin-bottom: 3rem;
}

/**
  * floated image - (_document-setup.scss)  
  * -------------------------------------------------------------------
  */
img.pull-right {
  margin: 0.9rem 0 0 2.4rem;
}
img.pull-left {
  margin: 0.9rem 2.4rem 0 0;
}

/**
  * block grid paddings - (_document-setup.scss) 
  * -------------------------------------------------------------------
  */
.bgrid {
  padding: 0 20px;
}
@media only screen and (max-width: 1024px) {
  .bgrid {
    padding: 0 18px;
  }
}
@media only screen and (max-width: 768px) {
  .bgrid {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 600px) {
  .bgrid {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 400px) {
  .bgrid {
    padding: 0;
  }
}

/**
  * pace.js styles - minimal  - (_document-setup.scss)
  * -------------------------------------------------------------------
  */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #9063cf;
  position: fixed;
  z-index: 900;
  top: 0;
  right: 100%;
  width: 100%;
  height: 6px;
}

/** 
  * ===================================================================
  * 05. forms - (_forms.scss)
  *
  * ------------------------------------------------------------------- 
  */
fieldset {
  border: none;
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  display: block;
  height: 6rem;
  padding: 1.5rem 0;
  border: 0;
  outline: none;
  vertical-align: middle;
  color: #313131;
  font-family: "poppins-regular", sans-serif;
  font-size: 1.5rem;
  line-height: 3rem;
  max-width: 100%;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.ss-custom-select {
  position: relative;
  padding: 0;
}
.ss-custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  margin: 0;
  line-height: 3rem;
  vertical-align: middle;
}
.ss-custom-select select option {
  padding-left: 2rem;
  padding-right: 2rem;
}
.ss-custom-select select::-ms-expand {
  display: none;
}
.ss-custom-select::after {
  content: "\f0d7";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  margin-top: -10px;
  bottom: auto;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 18px;
  text-align: center;
  pointer-events: none;
  color: #252525;
}

/* IE9 and below */
.oldie .ss-custom-select::after {
  display: none;
}
textarea {
  min-height: 25rem;
}
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  color: #743cc3;
  border-bottom: 1px solid #9063cf;
}
label,
legend {
  font-family: "poppins-bold", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
  color: #3b3b3b;
  display: block;
}
input[type="checkbox"],
input[type="radio"] {
  display: inline;
}
label > .label-text {
  display: inline-block;
  margin-left: 1rem;
  font-family: "poppins-regular", sans-serif;
  line-height: inherit;
}
label > input[type="checkbox"],
label > input[type="radio"] {
  margin: 0;
  position: relative;
  top: 0.15rem;
}

/**
  * Style Placeholder Text  
  * -
  */
::-webkit-input-placeholder {
  color: #a1a1a1;
}
:-moz-placeholder {
  color: #a1a1a1; /* Firefox 18- */
}
::-moz-placeholder {
  color: #a1a1a1; /* Firefox 19+ */
}
:-ms-input-placeholder {
  color: #a1a1a1;
}
.placeholder {
  color: #a1a1a1 !important;
}

/** 
  * ===================================================================
  * 06. buttons - (_button-essentials.scss)
  *
  * ------------------------------------------------------------------- 
  */
.button,
a.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  font-family: "poppins-bold", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  height: 5.4rem;
  line-height: 5.4rem;
  padding: 0 3rem;
  margin: 0 0.3rem 1.2rem 0;
  background: #d8d8d8;
  color: #313131;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  border: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.button:hover,
a.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  background: #bebebe;
  color: #000000;
  outline: 0;
}
.button.button-primary,
a.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  background: #313131;
  color: #ffffff;
}
.button.button-primary:hover,
a.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  background: #1f1f1f;
}
button.full-width,
.button.full-width {
  width: 100%;
  margin-right: 0;
}
button.medium,
.button.medium {
  height: 5.7rem !important;
  line-height: 5.7rem !important;
  padding: 0 1.8rem !important;
}
button.large,
.button.large {
  height: 6rem !important;
  line-height: 6rem !important;
  padding: 0rem 3rem !important;
}
button.stroke,
.button.stroke {
  background: transparent !important;
  border: 3px solid #313131;
  line-height: 4.8rem;
}
button.stroke.medium,
.button.stroke.medium {
  line-height: 5.1rem !important;
}
button.stroke.large,
.button.stroke.large {
  line-height: 5.4rem !important;
}
button.stroke:hover,
.button.stroke:hover {
  border: 3px solid #9063cf;
  color: #9063cf;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
  * alert box - (_alert-box.scss)
  * -------------------------------------------------------------------
  */
.alert-box {
  padding: 2.1rem 4rem 2.1rem 3rem;
  position: relative;
  margin-bottom: 3rem;
  border-radius: 3px;
  font-family: "poppins-regular", sans-serif;
  font-size: 1.5rem;
}
.alert-box .close {
  position: absolute;
  right: 1.8rem;
  top: 1.8rem;
  cursor: pointer;
}
.ss-error {
  background-color: #ffd1d2;
  color: #e65153;
}
.ss-success {
  background-color: #c8e675;
  color: #758c36;
}
.ss-info {
  background-color: #d7ecfb;
  color: #4a95cc;
}
.ss-notice {
  background-color: #fff099;
  color: #bba31b;
}

/**
  * additional typo styles - (_additional-typo.scss)
  * -------------------------------------------------------------------
  */

/**
  * drop cap 
  */
.drop-cap:first-letter {
  float: left;
  margin: 0;
  padding: 1.5rem 0.6rem 0 0;
  font-size: 8.4rem;
  font-family: "poppins-bold", sans-serif;
  line-height: 6rem;
  text-indent: 0;
  background: transparent;
  color: #313131;
}

/**
  * line definition style
  */
.lining dt,
.lining dd {
  display: inline;
  margin: 0;
}
.lining dt + dt:before,
.lining dd + dt:before {
  content: "\A";
  white-space: pre;
}
.lining dd + dd:before {
  content: ", ";
}
.lining dd + dd:before {
  content: ", ";
}
.lining dd:before {
  content: ": ";
  margin-left: -0.2em;
}

/**
  * dictionary definition style
  */
.dictionary-style dt {
  display: inline;
  counter-reset: definitions;
}
.dictionary-style dt + dt:before {
  content: ", ";
  margin-left: -0.2em;
}
.dictionary-style dd {
  display: block;
  counter-increment: definitions;
}
.dictionary-style dd:before {
  content: counter(definitions, decimal) ". ";
}

/** 
  * Pull Quotes
  * -----------
  * markup:
  *
  * <aside class="pull-quote">
  *		<blockquote>
  *			<p></p>
  *		</blockquote>
  *	</aside>
  *
  * --------------------------------------------------------------------- 
  */
.pull-quote {
  position: relative;
  padding: 2.1rem 3rem 2.1rem 0px;
}
.pull-quote:before,
.pull-quote:after {
  height: 1em;
  position: absolute;
  font-size: 8rem;
  font-family: Arial, Sans-Serif;
  color: #333;
}
.pull-quote:before {
  content: "\201C";
  top: 33px;
  left: 0;
}
.pull-quote:after {
  content: "\201D";
  bottom: -33px;
  right: 0;
}
.pull-quote blockquote {
  margin: 0;
}
.pull-quote blockquote:before {
  content: none;
}

/** 
  * Stats Tab
  * ---------
  * markup:
  *
  * <ul class="stats-tabs">
  *		<li><a href="#">[value]<em>[name]</em></a></li>
  *	</ul>
  *
  * Extend this object into your markup.
  *
  * ---------------------------------------------------------------------
  */
.stats-tabs {
  padding: 0;
  margin: 3rem 0;
}
.stats-tabs li {
  display: inline-block;
  margin: 0 1.5rem 3rem 0;
  padding: 0 1.5rem 0 0;
  border-right: 1px solid #ccc;
}
.stats-tabs li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
.stats-tabs li a {
  display: inline-block;
  font-size: 2.5rem;
  font-family: "poppins-bold", sans-serif;
  border: none;
  color: #252525;
}
.stats-tabs li a:hover {
  color: #9063cf;
}
.stats-tabs li a em {
  display: block;
  margin: 0.6rem 0 0 0;
  font-size: 1.4rem;
  font-family: "poppins-regular", sans-serif;
  color: #888888;
}

/**
  * skillbars - (_skillbars.scss)
  * -------------------------------------------------------------------
  */
.skill-bars {
  list-style: none;
  margin: 6rem 0 3rem;
}
.skill-bars li {
  height: 0.6rem;
  background: #a1a1a1;
  width: 100%;
  margin-bottom: 6rem;
  padding: 0;
  position: relative;
}
.skill-bars li strong {
  position: absolute;
  left: 0;
  top: -3rem;
  font-family: "poppins-bold", sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.5rem;
  line-height: 2.4rem;
}
.skill-bars li .progress {
  background: #313131;
  position: relative;
  height: 100%;
}
.skill-bars li .progress span {
  position: absolute;
  right: 0;
  top: -3.6rem;
  display: block;
  font-family: "poppins-regular", sans-serif;
  color: white;
  font-size: 1.1rem;
  line-height: 1;
  background: #313131;
  padding: 0.6rem 0.6rem;
  border-radius: 3px;
}
.skill-bars li .progress span::after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #313131;
  content: "";
}

.skill-bars li .percent5 {
  width: 5%;
}
.skill-bars li .percent10 {
  width: 10%;
}
.skill-bars li .percent15 {
  width: 15%;
}
.skill-bars li .percent20 {
  width: 20%;
}
.skill-bars li .percent25 {
  width: 25%;
}
.skill-bars li .percent30 {
  width: 30%;
}
.skill-bars li .percent35 {
  width: 35%;
}
.skill-bars li .percent40 {
  width: 40%;
}
.skill-bars li .percent45 {
  width: 45%;
}
.skill-bars li .percent50 {
  width: 50%;
}
.skill-bars li .percent55 {
  width: 55%;
}
.skill-bars li .percent60 {
  width: 60%;
}
.skill-bars li .percent65 {
  width: 65%;
}
.skill-bars li .percent70 {
  width: 70%;
}
.skill-bars li .percent75 {
  width: 75%;
}
.skill-bars li .percent80 {
  width: 80%;
}
.skill-bars li .percent85 {
  width: 85%;
}
.skill-bars li .percent90 {
  width: 90%;
}
.skill-bars li .percent95 {
  width: 95%;
}
.skill-bars li .percent100 {
  width: 100%;
}

/** 
  * ===================================================================
  * 08. common styles (_layout.scss)
  *
  * ------------------------------------------------------------------- 
  */
.grey-section {
  background: #ebebeb;
}
.grey-section p.lead {
  color: #7d7d7d;
}
.section-intro {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 3.6rem;
  position: relative;
}
.section-intro h1 {
  font-family: "poppins-semibold", serif;
  font-size: 3.6rem;
  color: #313131;
  line-height: 1.25;
  margin-bottom: 1.2rem;
}
.section-intro h5 {
  color: #9063cf;
  font-size: 1.6rem;
  line-height: 1.875;
  margin-bottom: 0.3rem;
  letter-spacing: 0.4rem;
}

/**
  * responsive:
  * common styles
  * ------------------------------------------------------------------- 
  */
@media only screen and (max-width: 768px) {
  .section-intro {
    max-width: 650px;
  }
  .section-intro h1 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 600px) {
  .section-intro h1 {
    font-size: 2.6rem;
  }
  .section-intro h5 {
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
  }
}
@media only screen and (max-width: 400px) {
  .section-intro h1 {
    font-size: 2.4rem;
  }
}

/* navigation panel */
#main-nav-wrap {
  display: block;
  width: 100%;
  font-family: "poppins-medium", sans-serif;
  font-size: 1.5rem;
  position: absolute;
  top: 100%;
  left: 0;
}

/* dropdown nav */
.main-navigation {
  background: #000000;
  padding: 24px 30px 42px;
  margin: 0;
  width: 100%;
  height: auto;
  clear: both;
  display: none;
}
.main-navigation > li {
  display: block;
  height: auto;
  text-align: left;
  padding: 0;
}
.main-navigation li a {
  display: block;
  color: #ffffff;
  width: auto;
  padding: 15px 0;
  line-height: 16px;
  border: none;
}
.main-navigation li a:hover {
  color: #9063cf;
  padding-left: 1rem;
}
.main-navigation li.current > a {
  background: none;
  color: #9063cf;
}

/**
  * responsive:
  * header styles
  * --------------------------------------------------------------- 
  */
@media only screen and (max-width: 1024px) {
  header .top-bar {
    left: 60px;
  }
}
@media only screen and (max-width: 768px) {
  header .top-bar {
    left: 50px;
  }
}
@media only screen and (max-width: 600px) {
  header .top-bar {
    left: 35px;
  }
}
@media only screen and (max-width: 400px) {
  header .top-bar {
    left: 25px;
  }
}

/** 
  * ===================================================================
  * 16. stats - (_layout.scss)
  *
  * ------------------------------------------------------------------- 
  */
#stats {
  background: #5d309c;
  padding-top: 7.2rem;
  padding-bottom: 6rem;
  text-align: center;
}
#stats .row {
  max-width: 1440px;
}
#stats .stat {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  min-height: 17.4rem;
}
#stats .stat:first-child {
  border: none;
}
#stats .icon-part i {
  font-size: 4.8rem;
  color: #000000;
}
#stats .stat-count {
  color: #ffffff;
  font-size: 3.6rem;
  margin-top: 1.2rem;
  margin-bottom: 0;
  font-family: "poppins-medium", sans-serif;
  color: white;
}
#stats .stat-title {
  color: rgba(255, 255, 255, 0.5);
}

/**
  * responsive:
  * stats
  * -------------------------------------------------------------------
  */
@media only screen and (max-width: 1024px) {
  #stats .stat:nth-child(n) {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
    padding-bottom: 1.5rem;
  }
  #stats .stat:nth-child(3n + 1) {
    border: none;
  }
}
@media only screen and (max-width: 768px) {
  #stats .stat:nth-child(n) {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
  }
  #stats .stat:nth-child(2n + 1) {
    border: none;
  }
}
@media only screen and (max-width: 600px) {
  #stats .stat:nth-child(n) {
    border: none;
  }
}

/**
  * loader animation - (_layout.scss)
  * --------------------------------------------------------------- 
  */
.s-loader {
  margin: 1.2rem auto 3rem;
  width: 70px;
  text-align: center;
  -webkit-transform: translateX(0.45rem);
  -ms-transform: translateX(0.45rem);
  transform: translateX(0.45rem);
}
.s-loader > div {
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.9rem;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.s-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.s-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/**
  * go to top - (_layout.scss)
  * ------------------------------------------------------------------- 
  */
#go-top {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 600;
  cursor: pointer;
}
#go-top a {
  display: block;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  background-color: #743cc3;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  
}

#go-top a:hover {
  background: #5d309c;
}

/*# sourceMappingURL=main.css.map */
