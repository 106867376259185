#resume {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

#resume .resume-header {
  text-align: center;
}
#resume .resume-header h2 {
  color: #9063cf;
}

#resume .resume-timeline {
  max-width: 980px;
}
#resume .timeline-wrap {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 6rem;
}
#resume .timeline-wrap::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 35%;
  top: 0;
}
#resume .timeline-block {
  position: relative;
  padding-top: 1.5rem;
}
#resume .timeline-ico {
  height: 4.8rem;
  width: 4.8rem;
  line-height: 4.8rem;
  background: #313131;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 35%;
  top: 0.9rem;
  margin-left: -2.4rem;
  padding-top: 5px;
  font-size: 2.7rem !important;
}
#resume .timeline-ico i {
  position: relative;
  left: 0.05rem;
  top: 0.2rem;
}

#resume .timeline-header {
  float: left;
  width: 34%;
  padding-right: 60px;
  text-align: right;
}
#resume .timeline-header h3 {
  margin-bottom: 0;
}
#resume .timeline-header p {
  font-family: "poppins-regular", sans-serif;
  font-size: 1.6rem;
  color: #888888;
}
#resume .timeline-content {
  margin-left: 35%;
  padding-left: 60px;
  text-align: justify;
}

#resume .timeline-content h4 {
  margin-bottom: 0;
}

#resume .timeline-content .job-title {
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
#resume .timeline-content .job-title::after {
  content: "";
  display: block;
  height: 3px;
  width: 50px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  bottom: 0;
}

#resume .timeline-content .job-location {
  font-size: 1.4rem;
  font-weight: 500;
  color: #888888;
  margin: 0;
}

@media only screen and (max-width: 1024px) {
  #resume .timeline-header {
    padding-right: 50px;
  }
  #resume .timeline-header h3 {
    font-size: 2rem;
  }
  #resume .timeline-header p {
    font-size: 1.4rem;
  }
  #resume .timeline-content {
    padding-left: 50px;
  }
}
@media only screen and (max-width: 768px) {
  #resume .timeline-wrap::before {
    left: 2.4rem;
  }
  #resume .timeline-ico {
    left: 2.4rem;
  }
  #resume .timeline-header {
    float: none;
    width: auto;
    padding-right: 15px;
    text-align: left;
  }
  #resume .timeline-header h3 {
    font-size: 1.8rem;
  }
  #resume .timeline-header p {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  #resume .timeline-content {
    margin: 0;
  }
  #resume .timeline-content p {
    font-size: 1.5rem;
  }
  #resume .timeline-content h4 {
    padding-bottom: 0;
    padding-top: 2.1rem;
    font-size: 1.5rem;
  }
 
  #resume .timeline-content li {
    font-size: 1.5rem;
  }
  #resume .timeline-content .job-title {
    margin-bottom: 0;
  }
  #resume .timeline-content .job-title::after {
    bottom: auto;
    top: 0;
  }
  #resume .timeline-header,
  #resume .timeline-content {
    padding-left: 7rem;
  }
}
@media only screen and (max-width: 480px) {
  #resume .resume-header h2 {
    font-size: 2.2rem;
  }
  #resume .timeline-wrap::before {
    left: 1.8rem;
  }
  #resume .timeline-ico {
    height: 3.6rem;
    width: 3.6rem;
    line-height: 3.6rem;
    left: 1.8rem;
    margin-left: -1.8rem;
    font-size: 2rem !important;
    padding-top: 3px;
  }
  #resume .timeline-header,
  #resume .timeline-content {
    padding-left: 5.5rem;
  }
}
