#about {
  background: #ffffff;
  padding-top: 12rem;
  padding-bottom: 12rem;
}
#about .section-intro {
  margin-bottom: 3rem;
}

.intro-info {
  margin-top: 4.2rem;
  margin-right: -80px;
  margin-left: -80px;
}

.intro-info .lead {
  text-align: justify;
  font-size: 2rem;
}

.about-content {
  position: relative;
  text-align: left;
  max-width: 850px;
  margin-bottom: 3.6rem;
}
.about-content h3 {
  font-family: "poppins-bold", sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
}

.about-content .skills {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.about-content .skills .skill {
  width: calc(16.66666% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
  user-select: none;
}

.about-content .skills .skill:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.about-content .skills .skill img {
  height: 8rem;
  width: 8rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 992px) {
  .intro-info {
    margin-right: 0;
    margin-left: 0;
  }
  .intro-info .lead {
    font-size: 1.7rem;
  }
  .about-content .skills .skill {
    width: calc(20% - 20px);
  }
}
@media only screen and (max-width: 768px) {

  .about-content h3 {
    text-align: center;
  }
  .about-content .skills .skill img {
    height: 7rem;
    width: 7rem;
  }
  .about-content .skills .skill {
    width: calc(25% - 20px);
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 576px) {
  .intro-info {
    text-align: center;
    margin-top: 3rem;
  }
  .intro-info .lead {
    padding-left: 0;
    text-align: justify;
    margin-top: 2rem;
    font-size: 1.6rem;
  }
  .about-content .skills .skill img {
    height: 6rem;
    width: 6rem;
  }

  .about-content .skills .skill {
    width: calc(33.33333% - 20px);
    font-size: 1.3rem;
  }
}
