footer {
  padding: 3rem;
  font-size: 1.4rem;
  font-family: "poppins-regular", sans-serif;
}
footer .berkan,
footer .berkan:visited {
  color: #ffffff;
  cursor: pointer;
}
footer .berkan:hover,
footer .berkan:focus {
  color: #9063cf;
}
footer .row {
  max-width: 900px;
  text-align: center;
  padding: 20px;
}

footer .copyright span {
  display: inline-block;
}
footer .copyright span::after {
  content: "|";
  display: inline-block;
  padding: 0 1rem 0 1.2rem;
  color: rgba(255, 255, 255, 0.1);
}
footer .copyright span:last-child::after {
  display: none;
}

@media only screen and (max-width: 768px) {
  footer {
    text-align: center;
  }
  footer .social {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  footer .copyright span {
    display: block;
  }
  footer .copyright span::after {
    display: none;
  }
}
