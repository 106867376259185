#certificates {
  padding-top: 15rem;
  padding-bottom: 15rem;
  background-color: #ebebeb;
}

#certificates .projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

#certificates .card {
  width: calc(33% - 2rem);
  user-select: none;
  cursor: pointer;
}

#certificates .card .card-img {
  height: 250px;
  width: 100%;
  transition: 0.5s all ease-in-out;
  overflow: hidden;
}

#certificates .card:hover .card-img {
  transform: scale(1.1);
}

#certificates .card .card-content {
  text-align: center;
}

#certificates .card .card-title {
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 4px;
}

#certificates .card .card-text {
  font-size: 16px;
  margin-top: 0;
  color: black;
}

@media only screen and (max-width: 992px) {
  #certificates .card {
    width: calc(50% - 2rem);
  }
}
@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
  #certificates .projects {
    gap: 5rem;
  }
  #certificates .card {
    width: 100%;
  }
  #certificates .card:hover .card-img {
    transform: none;
  }
  #certificates .card .card-img {
    height: 300px;
  }

  #certificates .card .card-title {
    font-size: 17px;
  }

  #certificates .card .card-text {
    font-size: 15px;
  }
}
